(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/browse-games/assets/javascripts/browse-games-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-data/browse-games/assets/javascripts/browse-games-actions.js');
"use strict";


const {
  FILTER_UPDATE,
  LOAD_QUERYSTRING
} = svs.components.marketplaceData.browseGames.helpers.constants;
const {
  getFiltersFromQuery
} = svs.components.marketplaceData.browseGames.helpers.queryString;
const updateFilterAction = (updatedFilter, groupId) => ({
  type: FILTER_UPDATE,
  updatedFilter,
  groupId
});
const loadFromQueryString = () => ({
  type: LOAD_QUERYSTRING,
  data: getFiltersFromQuery()
});
setGlobal('svs.components.marketplaceData.browseGames.actions', {
  FILTER_UPDATE,
  updateFilterAction,
  LOAD_QUERYSTRING,
  loadFromQueryString
});

 })(window);