(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/browse-games/assets/javascripts/helpers/query-string.js') >= 0) return;  svs.modules.push('/components/marketplace-data/browse-games/assets/javascripts/helpers/query-string.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  FILTER_QUERY_NAME,
  FILTER_TOGGLE_PRODUCT,
  FILTER_FRACTION_PRICE,
  FILTER_FRACTION_COUNT,
  FILTER_SYSTEM_PRICE,
  CLEAR_FILTER,
  QS_NAME,
  QS_PRODUCT,
  QS_SHARE_PRICE_MIN,
  QS_SHARE_PRICE_MAX,
  QS_SHARE_COUNT_MIN,
  QS_SHARE_COUNT_MAX,
  QS_SYSTEM_PRICE_MIN,
  QS_SYSTEM_PRICE_MAX,
  fractionPriceMaxDisplayValue,
  fractionPriceMinDisplayValue,
  fractionAmountMaxDisplayValue,
  fractionAmountMinDisplayValue,
  systemSizeMinDisplayValue,
  systemSizeMaxDisplayValue
} = svs.components.marketplaceData.browseGames.helpers.constants;
const {
  getProducts,
  getEnhancedProducts
} = svs.components.marketplace.helpers;
const supportedProducts = getProducts();
const groupedSubProducts = getEnhancedProducts().reduce((group, item) => _objectSpread(_objectSpread({}, group), {}, {
  [item.product]: [...(group[item.product] || []), ...(item.subProductId ? [item.subProductId] : [])]
}), {});
const qs = new URLSearchParams(window.location.search);
const handleFilterMaxMinValues = _ref => {
  let {
    max,
    min,
    maxFieldConst,
    minFieldConst,
    maxValueConst,
    minValueConst
  } = _ref;
  if (max >= maxValueConst) {
    qs.delete(maxFieldConst);
  } else if (max <= minValueConst) {
    qs.set(maxFieldConst, minValueConst);
  } else {
    qs.set(maxFieldConst, max);
  }
  if (min <= minValueConst) {
    qs.delete(minFieldConst);
  } else if (min >= maxValueConst) {
    qs.set(minFieldConst, maxValueConst);
  } else {
    qs.set(minFieldConst, min);
  }
};
const getFiltersFromQuery = () => {
  var _ref2;
  const qs = new URLSearchParams(window.location.search);
  const searchName = qs.get(QS_NAME) || '';
  const products = qs.getAll(QS_PRODUCT);
  const fractionPriceMin = parseInt(qs.get(QS_SHARE_PRICE_MIN), 10) || fractionPriceMinDisplayValue;
  const fractionPriceMax = parseInt(qs.get(QS_SHARE_PRICE_MAX), 10) || fractionPriceMaxDisplayValue;
  const fractionCountMin = parseInt(qs.get(QS_SHARE_COUNT_MIN), 10) || fractionAmountMinDisplayValue;
  const fractionCountMax = parseInt(qs.get(QS_SHARE_COUNT_MAX), 10) || fractionAmountMaxDisplayValue;
  const systemPriceMin = parseInt(qs.get(QS_SYSTEM_PRICE_MIN), 10) || systemSizeMinDisplayValue;
  const systemPriceMax = parseInt(qs.get(QS_SYSTEM_PRICE_MAX), 10) || systemSizeMaxDisplayValue;

  const productsWithReplacedRacingName = products.map(product => product.replace('travochgalopp', 'hastar'));
  const productList = (_ref2 = productsWithReplacedRacingName || products) === null || _ref2 === void 0 ? void 0 : _ref2.filter(p => supportedProducts.includes(p));
  const subProducts = productList.reduce((acc, product) => {
    var _groupedSubProducts$p;
    const hasSubProducts = (_groupedSubProducts$p = groupedSubProducts[product]) === null || _groupedSubProducts$p === void 0 ? void 0 : _groupedSubProducts$p.length;
    if (!hasSubProducts) {
      return acc;
    }
    const querySubProducts = qs.getAll(product).map(Number).filter(subProduct => groupedSubProducts[product].includes(subProduct));
    const subProducts = querySubProducts.length ? querySubProducts : groupedSubProducts[product];
    return _objectSpread(_objectSpread({}, acc), {}, {
      [product]: subProducts
    });
  }, {});
  const data = {
    searchName,
    products: productList,
    subProducts,
    fractionPriceMin,
    fractionPriceMax,
    fractionCountMin,
    fractionCountMax,
    systemPriceMax,
    systemPriceMin
  };
  return data;
};
const querystringHandler = action => {
  var _action$updatedFilter, _action$updatedFilter2;
  const key = (action === null || action === void 0 || (_action$updatedFilter = action.updatedFilter) === null || _action$updatedFilter === void 0 ? void 0 : _action$updatedFilter.key) || action.type;
  const value = action === null || action === void 0 || (_action$updatedFilter2 = action.updatedFilter) === null || _action$updatedFilter2 === void 0 ? void 0 : _action$updatedFilter2.value; 

  switch (key) {
    case FILTER_QUERY_NAME:
      qs.set(QS_NAME, value.name);
      break;
    case FILTER_TOGGLE_PRODUCT:
      {
        const filterProducts = new Set(qs.getAll(QS_PRODUCT));
        const filterSubProducts = new Set(qs.getAll(value.product));
        if (value.subProductId) {
          const subProductAsString = "".concat(value.subProductId);
          if (filterSubProducts.has(subProductAsString)) {
            filterSubProducts.delete(subProductAsString);
          } else {
            filterSubProducts.add(subProductAsString);
          }
          qs.delete(value.product);
          filterSubProducts.forEach(subProductId => qs.append(value.product, subProductId));
        }
        if (filterProducts.has(value.product) && !filterSubProducts.size) {
          filterProducts.delete(value.product);
        } else {
          filterProducts.add(value.product);
        }
        qs.delete(QS_PRODUCT);
        filterProducts.forEach(product => qs.append(QS_PRODUCT, product));
        break;
      }
    case FILTER_FRACTION_PRICE:
      handleFilterMaxMinValues({
        max: value.selectedMax,
        min: value.selectedMin,
        maxValueConst: fractionPriceMaxDisplayValue,
        minValueConst: fractionPriceMinDisplayValue,
        maxFieldConst: QS_SHARE_PRICE_MAX,
        minFieldConst: QS_SHARE_PRICE_MIN
      });
      break;
    case FILTER_FRACTION_COUNT:
      handleFilterMaxMinValues({
        max: value.selectedMax,
        min: value.selectedMin,
        maxValueConst: fractionAmountMaxDisplayValue,
        minValueConst: fractionAmountMinDisplayValue,
        maxFieldConst: QS_SHARE_COUNT_MAX,
        minFieldConst: QS_SHARE_COUNT_MIN
      });
      break;
    case FILTER_SYSTEM_PRICE:
      handleFilterMaxMinValues({
        max: value.selectedMax,
        min: value.selectedMin,
        maxValueConst: systemSizeMaxDisplayValue,
        minValueConst: systemSizeMinDisplayValue,
        maxFieldConst: QS_SYSTEM_PRICE_MAX,
        minFieldConst: QS_SYSTEM_PRICE_MIN
      });
      break;
    case CLEAR_FILTER:
      [QS_NAME, QS_PRODUCT, QS_SHARE_PRICE_MIN, QS_SHARE_PRICE_MAX, QS_SHARE_COUNT_MIN, QS_SHARE_COUNT_MAX, QS_SYSTEM_PRICE_MIN, QS_SYSTEM_PRICE_MAX, ...supportedProducts].forEach(param => qs.delete(param));
      break;
    default:
      if (qs.get('travochgalopp')) {
        qs.delete('travochgalopp');
        qs.delete(QS_PRODUCT, 'travochgalopp');

        qs.append('hastar', '14');
        qs.append(QS_PRODUCT, 'hastar');
      }
      break;
  }
  return qs;
};
setGlobal('svs.components.marketplaceData.browseGames.helpers.queryString', {
  getFiltersFromQuery,
  querystringHandler,
  constants: {
    QS_NAME,
    QS_PRODUCT,
    QS_SHARE_PRICE_MIN,
    QS_SHARE_PRICE_MAX,
    QS_SHARE_COUNT_MIN,
    QS_SHARE_COUNT_MAX,
    QS_SYSTEM_PRICE_MIN,
    QS_SYSTEM_PRICE_MAX
  }
});

 })(window);