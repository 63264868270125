(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/browse-games/assets/javascripts/helpers/constants.js') >= 0) return;  svs.modules.push('/components/marketplace-data/browse-games/assets/javascripts/helpers/constants.js');
"use strict";


const constants = {
  FILTER_UPDATE: 'BROWSE_GAMES/FILTER_UPDATE',
  FILTER_QUERY_NAME: 'BROWSE_GAMES/FILTER_QUERY_NAME',
  FILTER_TOGGLE_PRODUCT: 'BROWSE_GAMES/FILTER_TOGGLE_PRODUCT',
  FILTER_FRACTION_PRICE: 'BROWSE_GAMES/FILTER_FRACTION_PRICE',
  FILTER_FRACTION_COUNT: 'BROWSE_GAMES/FILTER_FRACTION_COUNT',
  FILTER_SYSTEM_PRICE: 'BROWSE_GAMES/FILTER_SYSTEM_PRICE',
  CLEAR_FILTER: 'BROWSE_GAMES/CLEAR_FILTER',
  LOAD_QUERYSTRING: 'BROWSE_GAMES/LOAD_QUERYSTRING',
  QS_NAME: 'search',
  QS_PRODUCT: 'products',
  QS_SHARE_PRICE_MIN: 'sharePriceMin',
  QS_SHARE_PRICE_MAX: 'sharePriceMax',
  QS_SHARE_COUNT_MIN: 'shareCountMin',
  QS_SHARE_COUNT_MAX: 'shareCountMax',
  QS_SYSTEM_PRICE_MIN: 'systemPriceMin',
  QS_SYSTEM_PRICE_MAX: 'systemPriceMax',
  fractionPriceMaxDisplayValue: 1000,
  fractionPriceMinDisplayValue: 10,
  fractionAmountMaxDisplayValue: 50,
  fractionAmountMinDisplayValue: 2,
  systemSizeMinDisplayValue: 20,
  systemSizeMaxDisplayValue: 42000
};
setGlobal('svs.components.marketplaceData.browseGames.helpers.constants', constants);

 })(window);